import React, { Component } from "react"
import Slider from "react-slick"

export default class Carousel extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      adaptiveHeight: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            dots: false,
          },
        },
      ],
    }

    return (
      <div className={"hero-carousel"}>
        <Slider {...settings}>
          {this.props.news &&
            this.props.news.map(article => (
              <div className="carousel-item" key={article.id}>
                {article.relationships.media.data.length > 0 && (
                  <img
                    src={article.relationships.media.data[0].attributes.url}
                    className={"carousel-item-image"}
                    alt={""}
                  />
                )}

                <div className={"carousel-item-body-wrapper"}>
                  <div className={"carousel-item-body"}>
                    <h2 className="carousel-item-header">
                      {article.attributes.title}
                    </h2>

                    <div
                      className="carousel-item-abstract"
                      dangerouslySetInnerHTML={{
                        __html: article.attributes.excerpt,
                      }}
                    />
                    <a
                      className="btn btn-primary carousel-item-read-more"
                      href={"news/articolo/" + article.attributes.slug}
                    >
                      Scopri di più
                    </a>
                  </div>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    )
  }
}
