import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../styles/app.scss"
import Carousel from "../../components/carousel"
import LatestNews from "../../components/latestNews"
import FeaturedProducts from "../../components/featuredProducts"

class Index extends Component {
  state = {}
  render() {
    const {
      carouselNews,
      latestNews,
      featuredProducts,
    } = this.props.pageContext
    return (
      <Layout classes={"home"}>
        <SEO
          title="Home"
          keywords={[`vision care`, `lenti a contatto`]}
          description="Prova descrizione"
        />

        <Carousel news={carouselNews} />

        <section className={"latest-news-wrapper"}>
          <div className={"container"}>
            <LatestNews news={latestNews} />
          </div>
        </section>

        <section className={"featured-products-wrapper"}>
          <header className={"featured-products-title"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"col"}>
                  <h3>Scopri i nostri prodotti</h3>
                </div>
              </div>
            </div>
          </header>

          <div className={"container"}>
            <div className={"row"}>
              <div className={"col"}>
                <FeaturedProducts featuredProducts={featuredProducts} />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Index
